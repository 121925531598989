import { render, staticRenderFns } from "./ChatLog.vue?vue&type=template&id=5aade5fa&"
import script from "./ChatLog.vue?vue&type=script&lang=js&"
export * from "./ChatLog.vue?vue&type=script&lang=js&"
import style0 from "./ChatLog.vue?vue&type=style&index=0&id=5aade5fa&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports